import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "http://www.dashbot.io/"
      }}>{`Dashbot`}</a>{` recently surpassed `}<strong parentName="p">{`70 Million messages`}</strong>{` processed.`}</p>
    <p>{`One of the questions that comes up often is around buttons — what’s better — free form input or button driven interfaces?`}</p>
    <p>{`We took a look at the data across Facebook bots on our platform for the past three months. Keep in mind this is looking across all the bots, not segmenting based on industry vertical or specific usecase.`}</p>
    <p>{`At a high level, what we found is a mixture of buttons and quick replies are good, but too much can have a negative effect on engagement.`}</p>
    <h2>{`Buttons and Quick Replies`}</h2>
    <p>{`Looking at the data over the past three months, the average percentage of messages into a bot that are buttons has recently dropped, while the percentage of quick replies is steadily rising.`}</p>
    <p>{`The average button percentage went from 29.5% in July to 31.5% in August and down to 26% in September. Meanwhile, quick replies have increased from 8.2% in July to 10% in August and 11.1% in September.`}</p>
    <h2><figure><img parentName="h2" {...{
          "src": "https://i.dashbot.io/wp-home/uploads/2016/10/07154345/0_C_iNgcPhsh9n2-nc.png",
          "alt": null
        }}></img></figure>{`What’s Better — Click or Chat?`}</h2>
    <p>{`In regards to Buttons and Quick Replies, like anything, in moderation they are great, but too much can have a negative impact on engagement.`}</p>
    <p>{`If we look at Buttons and Quick Replies together, we see the average percentage of messages into bots of those two types combined, over the three month period, is 34.8%.`}</p>
    <p>{`Bots that are within one standard deviation of the average have higher engagement than those with significantly higher button and quick reply percentages.`}</p>
    <p>{`In fact, once you get over ~60% of your inbound messages being a combination of buttons and quick replies, sessions per user per month, starts to drop off quite a bit.`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2016/10/07154344/bd359-1v_1hcf7p1fod9lztyqbp9g.png)
    </figure>
Time per Session in both extreme cases drops off a bit, and Messages In per
Session increases as the the percentage of buttons and replies increases.
    <h2>{`Taking Action…`}</h2>
    <p>{`One of the beauties of conversational interfaces is the ability to send in unstructured data — images, videos, button clicks, and more importantly a user’s own voice — their own words telling you what they want from your bot and what they think of your bot.`}</p>
    <p>{`We mentioned in a previous article that it’s important to monitor these different types of messages, and that adding handling for them can increase the engagement of your bot.`}</p>
    <p>{`If your bot interface is heavily button and quick reply based, make sure to check the other message types coming in as your users may be providing valuable information that could help you improve the overall bot experience.`}</p>
    <h2>{`Dashbot`}</h2>
    <p><a parentName="p" {...{
        "href": "http://www.dashbot.io/"
      }}>{`Dashbot`}</a>{` has tools to filter the types of messages coming in to give you a better idea of how users are interacting. With this information, you can add additional handling to improve the experience for your users.`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.dashbot.io/"
      }}>{`Dashbot`}</a>{` is a bot analytics platform that enables bot developers and enterprises to increase user acquisition, engagement, and monetization through actionable data and tools.`}</p>
    <p>{`In addition to traditional metrics like engagement and retention, we have bot specific processing and metrics like conversational analytics, sentiment analysis, and the full chat session transcripts.`}</p>
    <p>{`More importantly, we provide tools that make use of the data to improve acquisition and engagement, like live person take over of chat sessions.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      